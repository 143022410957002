<template>
  <vx-card title="Minimum Order Quantity">
        <minimum-order-quantity />
  </vx-card>
</template>
<script>
import MinimumOrderQuantity from "./MinimumOrderQuantity.vue";
export default {
  components: {
    MinimumOrderQuantity,
  },
};
</script>
