<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="moqID != null">Edit Minimum Order Quantity</b>
      <b v-else>Create Minimum Order Quantity</b>
    </span>
    <div class="vx-row mb-6 mt-3">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer Group1</label>
        <multiselect
          class="selectExample"
          v-model="selectCustomerGroup1.model"
          :options="selectCustomerGroup1.options"
          :multiple="false"
          :loading="selectCustomerGroup1.loading"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          name="CustomerGroup1"
          label="Customer Group 1"
          :custom-label="customLableSelect"
        >
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('CustomerGroup1')">{{
          errors.first("CustomerGroup1")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6 mt-3">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample"
          v-model="selectCustomer.model"
          :options="selectCustomer.options"
          :multiple="false"
          :allow-empty="false"
          :loading="selectCustomer.loading"
          :group-select="false"
          :internal-search="false"
          :max-height="160"
          :limit="4"
          @search-change="handleSearchSelectCG1"
          placeholder=" Type to search"
          track-by="id"
          name="Customer"
          label="Customer"
          :disabled="false"
          :custom-label="customLableSelect"
        >
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Customer')">{{
          errors.first("Customer")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date From</label>
        <datepicker
          :inline="false"
          v-model="data.dateFrom"
          placeholder="Date From"
          :disabled-dates="{ from: data.dateTo }"
        ></datepicker>
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">To</label>
        <datepicker
          :inline="false"
          v-model="data.dateTo"
          placeholder="Date To"
          :disabled-dates="{ to: data.dateFrom }"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Status</label>
        <vs-switch
          color="green"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          :style="w_switch"
          v-model="switch_model"
        >
          <span slot="on">Active</span>
          <span slot="off">In-Active</span>
          <!-- <template #off class="mx-5"> In-Active</template>
          <template #on> Active </template> -->
        </vs-switch>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Amount"
          name="Amount"
          type="text"
          v-validate="'required'"
          @keyup="data.amount = formatCurrency(data.amount)"
          v-model="data.amount"
        />
        <span class="text-danger text-sm" v-show="errors.has('Amount')">{{
          errors.first("Amount")
        }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    moqID: {
      type: Number,
    },
    dataEdit: {
      type: Object,
    },
    baseUrl: {
      type: String,
    },
  },

  data() {
    return {
      data: {
        customer_group_id1: 0,
        customer_id: 0,
        dateFrom: new Date(),
        dateTo: new Date(),
        amount: 0,
        is_active: false,
      },
      dataOptionCustomerGroup: {
        customer_group1: [],
      },
      cg1_iloop: 0,
      cust_iloop: 0,
      max_loop: 20,
      dataOptionCustomer: [],
      dateFrom: null,
      dateTo: null,
      disabledDateFrom: {},
      disabledDateTo: {},
      w_active: "width:65px", //width for switch label active
      w_inactive: "width:78px", //width for switch label in-active
      w_switch: "width:65px",
      switch_model: false,
      selectCustomerGroup1: {
        model: {
          id: 0,
          code: "",
          name: "",
        },
        loading: false,
        options: [],
      },
      selectCustomer: {
        model: {
          id: 0,
          code: "",
          name: "",
        },
        loading: false,
        options: [],
      },
    };
  },
  methods: {
    initialState() {
      this.data = {
        customer_group_id1: 0,
        customer_id: 0,
        dateFrom: null,
        dateTo: null,
        amount: 0,
        is_active: false,
      };
      this.cg1_iloop = 0;
      this.cust_iloop = 0;
      this.max_loop = 20;
      this.dateFrom = null;
      this.dateTo = null;
      this.disabledDateFrom = {};
      this.disabledDateTo = {};
      this.switch_model = false;
      this.selectCustomerGroup1.options = this.dataOptionCustomerGroup.customer_group1;
      this.selectCustomer.options = this.dataOptionCustomer;
      if (this.selectCustomerGroup1.options.length > 0) {
        this.selectCustomerGroup1.model = this.selectCustomerGroup1.options[0];
      } else {
        this.selectCustomerGroup1.model = {
          id: 0,
          code: "",
          name: "",
        };
      }
      this.selectCustomer.model = {
        id: 0,
        code: "",
        name: "",
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.moqID) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      var dateFrom = "";
      var dateTo = "";
      if (this.data.dateFrom) {
        dateFrom = moment(String(this.data.dateFrom)).format("YYYY-MM-DD");
      }
      if (this.data.dateTo) {
        dateTo = moment(String(this.data.dateTo)).format("YYYY-MM-DD");
      }
      return {
        id: this.moqID,
        customer_group_id1: this.selectCustomerGroup1.model.id,
        customer_id: this.selectCustomer.model.id,
        amount: this.formatNumber(this.data.amount),
        is_active: this.data.is_active,
        valid_from: dateFrom,
        valid_to: dateTo,
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "successfully added data",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    customLableSelect({ code, name }) {
      return `${code} - ${name}`;
    },
    setCustomer() {
      console.log("recheck, ", this.selectCustomer.loading);
      setTimeout(() => {
        if (this.selectCustomer.loading) {
          if (this.cust_iloop >= this.max_loop) {
            console.log("iterasi maksimum ", this.max_loop);
            this.cust_iloop = 0;
            return;
          }
          this.cust_iloop++;
          this.setCustomer();
        } else {
          this.cust_iloop = 0;
          var selectCustFilter = this.selectCustomer.options.filter((v) => {
            return v.id == this.dataEdit.customer_id;
          });
          console.log(selectCustFilter);
          if (selectCustFilter[0]) {
            this.selectCustomer.model = selectCustFilter[0];
          } else {
            this.selectCustomer.model = {
              id: this.dataEdit.customer_id,
              code: this.dataEdit.customer_code,
              name: this.dataEdit.customer_name,
            };
            this.selectCustomer.options.push(this.selectCustomer.model);
          }
        }
      }, 1000);
    },
    setCustomerGroup() {
      console.log("recheck, ", this.selectCustomer.loading);
      setTimeout(() => {
        if (this.selectCustomerGroup1.loading) {
          if (this.cg1_iloop >= this.max_loop) {
            console.log("iterasi maksimum ", this.max_loop);
            this.cg1_iloop = 0;
            return;
          }
          this.cg1_iloop++;
          this.setCustomerGroup();
        } else {
          this.cg1_iloop = 0;
          var selectCustGroupFilter = this.selectCustomerGroup1.options.filter((v) => {
            return v.id == this.dataEdit.customer_group_id_1;
          });
          console.log(selectCustGroupFilter);
          if (selectCustGroupFilter[0]) {
            this.selectCustomerGroup1.model = selectCustGroupFilter[0];
          } else {
            this.selectCustomerGroup1.model = {
              id: this.dataEdit.customer_group_id_1,
              code: this.dataEdit.customer_group_code_1,
              name: this.dataEdit.customer_group_name_1,
            };
            this.selectCustomerGroup1.options.push(this.selectCustomerGroup1.model);
          }
        }
      }, 1000);
    },
    getData() {
      if (this.moqID) {
        this.setCustomer();
        this.setCustomerGroup();
        this.data.dateFrom = new Date(this.dataEdit.valid_from);
        this.data.dateTo = new Date(this.dataEdit.valid_to);
        this.data.amount = this.dataEdit.amount;
        this.switch_model = this.dataEdit.is_active;
      }
    },
    getOptionCustomerGroup() {
      this.selectCustomerGroup1.loading = true;
      this.$http
        .get(this.baseUrl + "/customer-group")
        .then((resp) => {
          this.selectCustomerGroup1.loading = false;
          if (resp.code == 200) {
            var dataCustomerGroup1 = [];
            if (resp.data.customer_group1) {
              resp.data.customer_group1.forEach((v) => {
                dataCustomerGroup1.push({
                  id: v.id,
                  code: v.code,
                  name: v.name,
                });
              });
            }
            this.dataOptionCustomerGroup.customer_group1 = dataCustomerGroup1;
            this.selectCustomerGroup1.options = dataCustomerGroup1;
            if (dataCustomerGroup1.length > 0) {
              this.selectCustomerGroup1.model = dataCustomerGroup1[0];
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.selectCustomerGroup1.loading = false;
          console.log(error);
        });
    },
    getOptionCustomer(search = "") {
      this.selectCustomer.loading = true;
      this.$http
        .get(this.baseUrl + "/customer", {
          params: {
            search: search,
            limit: 50,
          },
        })
        .then((resp) => {
          this.selectCustomer.loading = false;
          if (resp.code == 200) {
            var dataCustomer = [];
            if (resp.data) {
              resp.data.forEach((v) => {
                dataCustomer.push({
                  id: v.id,
                  code: v.code,
                  name: v.name,
                });
              });
            }
            this.dataOptionCustomer = dataCustomer;
            this.selectCustomer.options = dataCustomer;
            if (dataCustomer.length > 0) {
              if (!this.selectCustomer.model) {
                this.selectCustomer.model = dataCustomer[0];
              }
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.selectCustomer.loading = false;
          console.log(error);
        });
    },
    handleSearchSelectCG1(query) {
      this.getOptionCustomer(query);
    },
    formatNumber(v) {
      return v.toString().replace(/\D/g, "") * 1;
    },
    formatCurrency(v) {
      v = v.toString().replace(/\D/g, "") * 1;
      return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted() {
    if (this.moqID) {
      this.getData();
    }
    this.getOptionCustomer();
    this.getOptionCustomerGroup();
  },
  computed: {},
  filters: {},
  watch: {
    moqID(v) {
      if (v) {
        this.getData();
      } else {
        this.initialState();
      }
    },
    switch_model(v) {
      if (v) {
        this.data.is_active = true;
        this.w_switch = this.w_active;
      } else {
        this.data.is_active = false;
        this.w_switch = this.w_inactive;
      }
    },
  },
};
</script>
